import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../Api/api.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  form!:FormGroup
  fullUrl:any
  latitude:any
  longitude:any
  queryurl:any
  queryParams:any
  querypar:any

  constructor(private fb:FormBuilder,
    private router:Router,
    private route: ActivatedRoute,
    private apiservice:ApiService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
    ) { 
    this.form =this.fb.group({
      name:['',Validators.required],
      phone:['',Validators.required],
      location:['']
    })
    this.route.queryParams.subscribe((res: any) => {
      this.querypar=Object.keys(res)[0]
    
      
    });
    const smallboxNumber = this.route.snapshot.queryParamMap.get(this.querypar);
 this.queryurl = '='+smallboxNumber
    console.log('smallboxNumber:', this.queryurl);
    this.queryParams  = { [this.querypar]: this.queryurl };
   
    // console.log(queryParams,'full url')
   
  }

  ngOnInit(): void {
    const queryParams = this.route.snapshot.queryParams;
    this.route.url.subscribe(urlSegments => {
      this.fullUrl = window.location.origin + window.location.pathname  + window.location.search;
      
      console.log(queryParams,'query');
      console.log(window.location.pathname);
      console.log(window.location.search);
    });
    this.getLocation();
  }
  getLocation() {
    this.apiservice.getLocation().subscribe(
      (position: any) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
       console.log('lat:',this.latitude)
       console.log('lat:',this.longitude)
      },
      (error) => {
        console.log('Error getting location:', error);
        this.latitude = '';
        this.longitude = '';
       console.log('lat:',this.latitude)
       console.log('lat:',this.longitude)
      }
    );
  }
  submit() { 
 
    console.log(this.form.value);
    if (this.form.invalid) {
      this.apiservice.snackbar('Please fill All the Required Field', 'red-snackbar');
    } else {
      
      this.apiservice.customerinfo(this.form.value,this.fullUrl,this.latitude,this.longitude).subscribe(
        (res: any) => {
          if (res.responseCode === 200) {
            this.apiservice.snackbar(res.responseMessage.message, 'green-apiservice.');
            this.form.reset('');
            const queryParams = this.route.snapshot.queryParams;
            this.router.navigate(['scan-details2'], { queryParams, queryParamsHandling: 'preserve' });
            // this.router.navigate(['scan-details2']);
            // window.location.href=`${this.baseURLtraining}?authtoken=${authToken}&usertype=${userType}&zone=${zone}&designation=${designation}&name=${name}&isAdminForSecurity=${securityperm}`;
            // this.router.navigate(['scan-details2']);
          } else if (res.responseCode === 0) {
            this.apiservice.snackbar(res.responseMessage.message, 'red-snackbar');
          }
        },
        (err: any) => {
          this.apiservice.snackbar('Network Error', 'red-snackbar');
        }
      );
    }
    
  }
}
