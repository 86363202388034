<div
  style="text-align: end; margin-top: 20px; margin-bottom: 10px"
  id="nonprintable"
>
  <button
    id="btnPrint"
    type="button"
    class="btn btn-primary btn1"
    onclick="print()"
  >
    Print
  </button>
  <!-- <ngx-print-element #element="element" >
      <button id="ele" class="btnPrint" class="btn btn-primary btn1" (click)="element.print('printqr', {printMode: 'template'})" >Print</button>
    </ngx-print-element> -->
  <button
    type="button"
    class="btn btn-warning btn1"
    style="margin-left: 10px"
    (click)="Back()"
  >
    Back
  </button>
</div>

<div id="printqr" class="pagenumber" *ngIf="!outerbox">
  <div
    style="
      margin-bottom: 3%;
      margin-left: auto;
      margin-right: auto;
      display: block;
    "
    *ngFor="let i of qrcodearray; let in = index"
  >
    <div
      class="printable"
      style="
        text-align: center;
        display: block;
        margin-bottom: 6%;
        align-items: center;
        display: flex;
      "
    >
      <div style="flex: 0.5"></div>
      <div style="text-align: initial; padding-right: 20px; margin-top: -1%">
        <div style="margin-bottom: 5px">{{ lotno.substring(0, 12) }}</div>
        <!-- ngif itemcode is used bcoz only in mixing qrcode whe have to show itemcode and batchnumber -->
        <div style="margin-bottom: 5px" *ngIf="itemcode">{{ itemcode }}</div>
        <div style="margin-bottom: 5px" *ngIf="itemcode">{{ batchnumber }}</div>
        <div style="margin-bottom: 5px">
          {{ manufacturing | date : "MMM-YYYY" }}
        </div>
        <div style="margin-bottom: 5px">{{ expiry }}</div>
        <div style="margin-bottom: 5px">{{ "Rs. " + price }}</div>
        <div style="margin-bottom: 5px" *ngIf="fermenter">
          {{ fermenter.substring(0, 5) + lotno.substring(12) }}
        </div>
        <div style="margin-bottom: 5px" *ngIf="data.boxtype == 'Planned'">
          {{ type.substring(0, 1) + "-" + qrrange.split(",")[in] }}-{{
            manufacturing | date : "ddMMyyyy"
          }}
        </div>
        <!-- <div style="margin-bottom: 5px" *ngIf="data.boxtype == 'Mix'">
          {{ type.substring(0, 1) + "-" + qrrange.split("OuterBox")[1] }}-{{
            manufacturing | date : "ddMMyyyy"
          }}
        </div> -->
        <div style="margin-bottom: 5px" *ngIf="data.boxtype == 'Mix'">
          {{ data.common_part.split("@")[1].slice(0, -1) }}-{{ i.split("OuterBox")[1].split('@MOB@OuterBox')[0] }}-{{
            manufacturing | date : "ddMMyyyy"
          }}     </div>
      </div>

      <div>
        <qrcode
          [qrdata]="i"
          [errorCorrectionLevel]="'M'"
          [elementType]="'url'"
          [width]="150"
        ></qrcode>
        <label style="padding: 10px">{{ i.split("=")[1] }}</label>
      </div>
    </div>
    <hr />
  </div>
</div>

<div id="printqr" class="pagenumber" *ngIf="outerbox">
  <div
    style="
      margin-bottom: 3%;
      margin-left: auto;
      margin-right: auto;
      display: block;
    "
  >
    <div
      class="printable"
      style="
        text-align: center;
        display: block;
        margin-bottom: 6%;
        align-items: center;
      "
    >
      <div style="flex: 0.5"></div>
      <div style="text-align: initial; padding-right: 20px; margin-top: -1%">
        <div style="margin-bottom: 5px">{{ lotno.substring(0, 12) }}</div>

        <div style="margin-bottom: 5px">
          {{ manufacturing | date : "MMM-YYYY" }}
        </div>
        <div style="margin-bottom: 5px">{{ expiry }}</div>
        <div style="margin-bottom: 5px">{{ "Rs. " + price }}</div>
        <div style="margin-bottom: 5px" *ngIf="fermenter">
          {{ fermenter.substring(0, 5) + lotno.substring(12) }}
        </div>
      </div>

      <div>
        <qrcode
          [qrdata]="outerbox"
          [errorCorrectionLevel]="'M'"
          [elementType]="'url'"
          [width]="150"
        ></qrcode>
        <label style="padding: 10px">{{ outerbox.split("=")[1] }}</label>
      </div>
    </div>
    <hr />
  </div>
</div>
