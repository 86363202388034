import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appAlphabetonly]'
})
export class AlphabetonlyDirective {

  @HostListener('input', ['$event']) onInput(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const sanitizedValue = input.value.replace(/[^a-zA-Z ]/g, '');

    input.value = sanitizedValue;
  }

}
