import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ApiService } from '../Api/api.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginform: FormGroup;
  hide = true;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private apiservice: ApiService,
    private snackBar: MatSnackBar
  ) {
    this.loginform = this.fb.group({
      username: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {}

  submit() {
    if (this.loginform.invalid) {
      this.loginform.markAllAsTouched();
    } else {
      this.apiservice.login(this.loginform.value).subscribe(
        (res: any) => {
          if (res.responseCode === 200) {
            localStorage.setItem('token', 'token');
            this.router.navigate(['/pages']);
            localStorage.setItem(
              'user_id',
              JSON.stringify(res.responseMessage.data.userdetail[0].id)
            );
            localStorage.setItem(
              'screenlist',
              JSON.stringify(res.responseMessage.data.screenlist)
            );

            localStorage.setItem(
              'oulist',
              JSON.stringify(res.responseMessage.data.oulist)
            );

            localStorage.setItem(
              'username',
              res.responseMessage.data.userdetail[0].Name
            );
          } else if (res.responseCode === 0) {
            this.snackbar(res.responseMessage, 'red-snackbar');
          }
        },
        (err: any) => {
          this.snackbar('Network Error', 'red-snackbar');
        }
      );
    }
  }

  snackbar(value: any, color: any) {
    this.snackBar.open(value, 'X', {
      duration: 3000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      panelClass: [color],
    });
  }
}
