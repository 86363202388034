import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../Api/api.service';

@Component({
  selector: 'app-print-inner',
  templateUrl: './print-inner.component.html',
  styleUrls: ['./print-inner.component.scss'],
})
export class PrintInnerComponent implements OnInit {
  qrcodearray: any = [];
  lotno: any;
  manufacturing: any;
  price: any;
  fermenter: any;

  expiry: any;
  type: any;
  outerbox: any;

  year: any;

  monthArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  batchnumber: any;
  itemcode: any;
  qrrange: any;
  data: any;

  constructor(
    private activateRouter: ActivatedRoute,
    private apiService: ApiService,
    private datepipe: DatePipe
  ) {
    this.activateRouter.queryParams.subscribe((res: any) => {
      if (res.id.includes('http://iplqr.mykumpany.com/scan-details')) {
        this.outerbox = res.id;
      } else {
        this.getQrCodeDetails(res.id);
      }
    });
  }

  ngOnInit(): void {
    console.log(this.expiry, 'expiryyyyyyy');
  }

  getQrCodeDetails(value: any) {
    let url: any;
    this.apiService.GetNormalPrinterQrCode(value).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.data = res.responseMessage.data[0];
          this.lotno = res.responseMessage.data[0].lot_no;
          this.manufacturing = res.responseMessage.data[0].mfg_date;
          this.expiry = res.responseMessage.data[0].exp_date;
          this.price = res.responseMessage.data[0].price;
          this.fermenter =
            res.responseMessage.data[0].common_part.split('@')[1];
          this.type = res.responseMessage.data[0].type;
          this.itemcode = res.responseMessage.data[0].item_code;
          this.batchnumber = res.responseMessage.data[0]?.batch;
          this.qrrange = res.responseMessage.data[0].qrrange;

          if (res.responseMessage.data[0].type == 'Outer') {
            url =
              res.responseMessage.data[0].common_part.split('OuterBox')[0] +
              'OuterBox';
          } else {
            url = res.responseMessage.data[0].common_part;
          }

          var array = res.responseMessage.data[0].qrrange.split(',');

          var date = new Date(this.manufacturing).getDate();
          if (date != 1) {
            if (new Date(this.expiry).getMonth() == 0) {
              this.year = new Date(this.expiry).getFullYear() - 1;
              this.expiry = this.monthArray[11] + '-' + this.year;
            } else {
              this.year = new Date(this.expiry).getFullYear();
              this.expiry =
                this.monthArray[new Date(this.expiry).getMonth() - 1] +
                '-' +
                this.year;
            }
          } else {
            this.expiry = this.datepipe.transform(this.expiry, 'MMM-yyyy');
          }
          array.forEach((element: any) => {
            this.qrcodearray.push(url + element);
          });

          console.log(this.qrcodearray, 'array');
        } else {
          window.close();
        }
      },
      (err: any) => {}
    );
  }

  Back() {
    window.close();
  }
}
