<!-- <div class="Main">
    <div class="main-container">
        <div id="MobileHeader" class="TopHeader">
            <div class="leftIcon"><img
                    src="../../../assets/pack5/aazadi.png"></div>
            <div class="rightIcon"><img
                    src="../../../assets/pack5/dhanuka logo.png"></div>
        </div>
        <div class="valid-main">
            <div class="heading">
                <div class="productImg"><img class="product-url" src="../../../assets/icons/download.png"><img
                     src="../../../assets/icons/1-loop-stamp-GIF1.gif" class="geniune"></div>
                <div class="thanksmsg">Thank you for scanning GENUINE DHANUKA Bottle.<div
                    > QR Code details:</div>
                </div>
            </div>
            <div class="table-container">
                <table cellpadding="0" cellspacing="0">
                    <tr>
                        <td class="right-border bottom-border name"> Product name </td>
                        <td id="redirectLink" class="bottom-border special_td"><a
                             target="_blank"
                                href="https://www.dhanuka.com/plant-growth-regulators/maxyld">MAXYLD 0.001% L<div
                                 id="product_detail">(Click to view product details.)</div>
                                </a><img src="../../../assets/icons/hand-pointer.gif"
                                style="width: 35px;"></td>
                    </tr>
                    <tr>
                        <td class="right-border bottom-border">Batch Number</td>
                        <td class="bottom-border">UHQ-0003</td>
                    </tr>
                    <tr>
                        <td class="right-border bottom-border">Manufacturing Date</td>
                        <td class="bottom-border">10-01-2022</td>
                    </tr>
                    <tr>
                        <td class="right-border bottom-border">Expiry Date</td>
                        <td class="bottom-border">08-01-2025</td>
                    </tr>
                    <tr>
                        <td class="right-border bottom-border">UID</td>
                        <td class="bottom-border">FDYV46YY4V</td>
                    </tr>
                    <tr>
                        <td class="right-border bottom-border">Product video</td>
                        <td id="redirectLink" class="bottom-border"><a
                                target="_blank" href="https://youtu.be/Wfqg0UigLoo">Click on the link to see</a></td>
                    </tr>
                    <tr>
                        <td class="right-border bottom-border">Manufacturing by</td>
                        <td class="bottom-border">Dhanuka Agritech Ltd</td>
                    </tr>
                    <tr>
                        <td class="right-border bottom-border">Marketed by</td>
                        <td class="bottom-border">Dhanuka Agritech Ltd</td>
                    </tr>
                    <tr class="cautionary-symbol">
                        <td class="right-border bottom-border">Cautionary Symbol</td>
                        <td id="caution_td" class="bottom-border">
                            <p>Caution</p><img width="80px" height="80px"
                                src="../../../assets/icons/caution.png">
                        </td>
                    </tr>
                    <tr>
                        <td class="right-border bottom-border">Registration Number</td>
                        <td class="bottom-border">CIR-65,700/2011-Gibberellic Acid (L) (314)-283
                        </td>
                    </tr>
                    <tr class="customer-number">
                        <td class="right-border bottom-border"><a
                                target="_parent" href="tel:1800-102-1022"> Customer Care Number </a></td>
                        <td id="redirectLink" class="bottom-border"><a
                                target="_parent" class="customer-number-value" href="tel:1800-102-1022"><img
                                 src="../../../assets/icons/phone.svg"><span
                                > 1800-102-1022 </span></a></td>
                    </tr>
                    <tr>
                        <td class="right-border bottom-border">Antidote Statement</td>
                        <td class="bottom-border">No specific antidote is known. Treat
                            symptomatically.</td>
                    </tr>
                    <tr>
                        <td class="right-border bottom-border">Label information</td>
                        <td id="redirectLink" class="bottom-border"><a
                                target="_blank"
                                href="https://www.dhanuka.com/storage/products/August2021/xMKfWkrRffcsFf1ibmMm.png">Click
                                on the link to see</a></td>
                    </tr>
                    <tr>
                        <td class="right-border bottom-border">Leaflet information</td>
                        <td id="redirectLink" class="bottom-border"><a
                                target="_blank"
                                href="https://dhanukasalesapp.in:8090/node/static/web-authentication-images/LeafletImages/DFU-MAXY.jpg">Click
                                on the link to see</a></td>
                    </tr>
                </table>
                <div class="disclaimer"><span>Disclaimer</span>: Product
                    image is for illustration only. Design on actual product pack may differ from shown product image.
                </div>
            </div>
        </div>
        <div class="RightSide">
            <div class="container">
                <div id="webHeading" class="TopHeader">
                    <div class="leftIcon"><img
                            src="../../../assets/pack5/aazadi.png"></div>
                    <div class="rightIcon"><img
                            src="../../../assets/pack5/dhanuka logo.png"></div>
                </div>
                <div class="middelHeading">
                    <h1>PROVIDING INNOVATIVE CROP SOLUTION TO INDIAN AGRICULTURE FROM PAST 4
                        DECADES</h1>
                </div>
                <div class="MiddleMain"><img id="mobileGrass"
                        src="../../../assets/pack5/anaag.png" class="grass">
                    <div class="Middle">
                        <div class="RightImg"><img class="kissan"
                                src="https://dhanukasalesapp.in:8090/node/static/web-authentication-images/user_portal/background_image.png"><img
                             class="transform"
                                src="https://dhanukasalesapp.in:8090/node/static/web-authentication-images/user_portal/caption_image.png">
                        </div>
                        <div class="mobileHeading">
                            <p>PROVIDING INNOVATIVE CROP SOLUTION TO INDIAN AGRICULTURE FROM PAST
                                4 DECADES</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="endFooter"><img id="webgrass"
            src="../../../assets/pack5/anaag.png" class="grass"><img
            src="../../../assets/pack5/trust-of.png"></div>
</div> -->


<div  class="main"style="background: url('../../../assets/pack5/Web-BG.png'); background-repeat: no-repeat; background-size: cover;">
<div class="d-flex flex-column flex-md-row">
    <div class="col valid-main left">
        <div class="heading ">
            <div class="productImg"><img class="product-url" src="../../../assets/pack5/download.png"><img
                src="../../../assets/pack5/1-loop-stamp-GIF1.gif" class="geniune"></div>
        <div>
            <h1 class="thanksmsg">Thank you for scanning GENUINE DHANUKA Bottle.
                </h1>
            <h1 class="thanksmsg">QR Code details:</h1>
        </div>
        </div>
        
        
    </div>
    <div class="col-2 right">
        <!-- <div class="leftIcon"><img
            src="../../../assets/pack5/aazadi.png"></div> -->
    <div class="rightIcon"><img
            src="../../../assets/pack5/dhanuka logo.png"></div>
    </div>
</div>
<div class="d-flex flex-column flex-md-row">
    <div class="col valid-main2">
        <div class="table-container">
            <table cellpadding="0" cellspacing="0">
                <tr>
                    <td class="right-border bottom-border name"> Product name </td>
                    <td id="redirectLink" class="bottom-border "> <div class="special_td"><a
                        target="_blank"
                           href="https://www.dhanuka.com/plant-growth-regulators/maxyld">MAXYLD 0.001% L<div
                            id="product_detail">(Click to view product details.)</div>
                           </a><img src="../../../assets/pack5/hand-pointer.gif"
                           style="width: 35px;"></div></td>
                </tr>
                <tr>
                    <td class="right-border bottom-border">Batch Number</td>
                    <td class="bottom-border">UHQ-0003</td>
                </tr>
                <tr>
                    <td class="right-border bottom-border">Manufacturing Date</td>
                    <td class="bottom-border">10-01-2022</td>
                </tr>
                <tr>
                    <td class="right-border bottom-border">Expiry Date</td>
                    <td class="bottom-border">08-01-2025</td>
                </tr>
                <tr>
                    <td class="right-border bottom-border">UID</td>
                    <td class="bottom-border">FDYV46YY4V</td>
                </tr>
                <tr>
                    <td class="right-border bottom-border">Product video</td>
                    <td id="redirectLink" class="bottom-border"><a
                            target="_blank" href="https://youtu.be/Wfqg0UigLoo">Click on the link to see</a></td>
                </tr>
                <tr>
                    <td class="right-border bottom-border">Manufacturing by</td>
                    <td class="bottom-border">Dhanuka Agritech Ltd</td>
                </tr>
                <tr>
                    <td class="right-border bottom-border">Marketed by</td>
                    <td class="bottom-border">Dhanuka Agritech Ltd</td>
                </tr>
                <tr class="cautionary-symbol">
                    <td class="right-border bottom-border">Cautionary Symbol</td>
                    <td  class="bottom-border"> <div id="caution_td">
                        <p>Caution</p><img width="50px" height="50px"
                        src="../../../assets/pack5/caution.png">
                    </div>
                     
                    </td>
                </tr>
                <tr>
                    <td class="right-border bottom-border">Registration Number</td>
                    <td class="bottom-border">CIR-65,700/2011-Gibberellic Acid (L) (314)-283
                    </td>
                </tr>
                <tr class="customer-number">
                    <td class="right-border bottom-border"><a
                            target="_parent" href="tel:1800-102-1022"> Customer Care Number </a></td>
                    <td id="redirectLink" class="bottom-border"><a
                            target="_parent" class="customer-number-value" href="tel:1800-102-1022"><img
                             src="../../../assets/icons/phone.svg"><span
                            > 1800-102-1022 </span></a></td>
                </tr>
                <tr>
                    <td class="right-border bottom-border">Antidote Statement</td>
                    <td class="bottom-border">No specific antidote is known. Treat
                        symptomatically.</td>
                </tr>
                <tr>
                    <td class="right-border bottom-border">Label information</td>
                    <td id="redirectLink" class="bottom-border"><a
                            target="_blank"
                            href="https://www.dhanuka.com/storage/products/August2021/xMKfWkrRffcsFf1ibmMm.png">Click
                            on the link to see</a></td>
                </tr>
                <tr>
                    <td class="right-border bottom-border">Leaflet information</td>
                    <td id="redirectLink" class="bottom-border"><a
                            target="_blank"
                            href="https://dhanukasalesapp.in:8090/node/static/web-authentication-images/LeafletImages/DFU-MAXY.jpg">Click
                            on the link to see</a></td>
                </tr>
            </table>
            <div class="disclaimer"><span>Disclaimer</span>: Product
                    image is for illustration only. Design on actual product pack may differ from shown product image.
                </div>
        </div>
    </div>
    <div class="col" style="position: relative;">
        <h1>PROVIDING INNOVATIVE CROP SOLUTION TO INDIAN AGRICULTURE FROM PAST 4 DECADES</h1>
        <div class="Middle">
            <div class="RightImg"><img class="kissan"
                    src="https://dhanukasalesapp.in:8090/node/static/web-authentication-images/user_portal/background_image.png"><img
                 class="transform"
                    src="https://dhanukasalesapp.in:8090/node/static/web-authentication-images/user_portal/caption_image.png">
            </div>
            <!-- <div class="mobileHeading">
                <p>PROVIDING INNOVATIVE CROP SOLUTION TO INDIAN AGRICULTURE FROM PAST 4 DECADES</p>
            </div> 
        -->
        </div>
    </div>
</div>
<div class="row">
    <div class="col ">
      <img src="../../../assets/pack5/anaag.png" style="width:100%;">
    </div>
</div>
   <span >
      
        <img src="../../../assets/pack5/trust-of.png" style="width:100%;">
      
</span>
</div>