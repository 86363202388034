import { Directive, Input, ElementRef, HostListener } from '@angular/core';

interface NumberRange {
  from: number;
  to: number;
}

@Directive({
  selector: '[appNumberRange]'
})
export class NumberRangeDirective {
  @Input() appNumberRange: NumberRange = { from: 1, to: 1 };

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: any) {
    const inputValue = event.target.value;

    // Allow an empty input value
    if (inputValue === '') {
      return;
    }

    const numericValue = Number(inputValue);

    if (numericValue < this.appNumberRange.from) {
      this.el.nativeElement.value = this.appNumberRange.from;
    } else if (numericValue > this.appNumberRange.to) {
      this.el.nativeElement.value = this.appNumberRange.to;
    }
  }
}
