import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../Api/api.service';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  //public dialogRef!: MatDialogRef<any>;

  screen: any = [];
  productionReceipt: any;
  name: any;
  screendialog = false;
  printDeleteDialog = false;
  moredetails = false;
  ItemDispatch = false;
  deleteDispatch = false;
  cancelbtn: any;
  submitbtn: any;
  iconflag: any;
  details: any;

  password: string = '';
  printer: any;

  hide: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogComponent>,
    private _apiService: ApiService
  ) {
    console.log(data, 'ppppp');
    console.log(this.data.printer, 'tttttttt');

    if (this.data.open == 'screen') {
      // alert('1')
      this.screen = this.data.message;
      this.name = this.data.name + '  ' + 'Screen Access';
      this.screendialog = true;
    } else if (this.data.open == 'delete') {
      // alert('2')
      dialogRef.disableClose = true;
      this.printDeleteDialog = true;

      this.cancelbtn = this.data.buttonText.cancel;
      this.submitbtn = this.data.buttonText.Yes;
      this.name = this.data.name;
      this.iconflag = this.data.message;
      this.printer = this.data.printer;
      console.log(this.printer,'printer value')
    } else if (this.data.open == 'Moredetails') {
      // alert('3')
      dialogRef.disableClose = true;
      this.moredetails = true;
      this.cancelbtn = this.data.buttonText.cancel;
      this.name = this.data.name;
      this.details = this.data.message;
      console.log(this.details);
    } else if (this.data.open == 'ItemCode') {
      dialogRef.disableClose = true;
      this.ItemDispatch = true;
      this.cancelbtn = this.data.buttonText.cancel;
      this.name = this.data.name;
      this.details = this.data.message;
    }
  }

  cancel() {}
  generate() {
    if (this.iconflag == 'print') {
      if (this.password == '') {
        alert('Please Enter Password');
      } else {
        this._apiService
          .printerPasswodVerification(this.printer, this.password)
          .subscribe((res: any) => {
            if (res.responseCode == 0) {
              alert(res.responseMessage.message);
            } else {
              this.dialogRef.close(true);
            }
          });
      }
    } else this.dialogRef.close(true);
  }

  ngOnInit(): void {}
}
