import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './shared/shared.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SmallQrPrintComponent } from './small-qr-print/small-qr-print.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ScanDetailsComponent } from './scan-details/scan-details.component';

import { DialogComponent } from './dialog/dialog.component';
import { TestComponent } from './test/test.component';
import { DatePipe } from '@angular/common';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { PrintInnerComponent } from './print-inner/print-inner.component';

import { KisanComponent } from './kisan/kisan.component';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SmallQrPrintComponent,
    ScanDetailsComponent,
    DialogComponent,
    TestComponent,
    PrintInnerComponent,
    KisanComponent,

  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxUiLoaderModule,
    QRCodeModule,
    ScrollingModule,
    
  
  ],
  providers: [DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
