import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ApiService } from '../Api/api.service';
import { environment } from '../../environments/environment';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-small-qr-print',
  templateUrl: './small-qr-print.component.html',
  styleUrls: ['./small-qr-print.component.scss'],
})
export class SmallQrPrintComponent implements OnInit {
  production: any;
  fermenter: any;
  Quantity: any;
  lotno: any;
  itemcode: any;
  type: any;
  qrcodearray: any = [];
  window: any;
  manufacturing: any;
  expiry: any;
  price: any;
  year: any;

  monthArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  public config = {
    printMode: 'template-popup',
    popupProperties:
      'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'Hello World',
    // templateString: '<header>I\'m part of the template header</header>{{printBody}}<footer>I\'m part of the template footer</footer>',
    // stylesheets: [{ rel: 'stylesheet', href: 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css' }],
    // styles: ['.table { color: red; }', '.table td { color: green; }']
  };

  constructor(
    private activateroute: ActivatedRoute,
    private router: Router,
    private datepipe: DatePipe
  ) {
    this.window = window;
    this.activateroute.queryParams.subscribe((res: any) => {
      (this.production = res.production),
        (this.fermenter = res.fullFermenter),
        (this.Quantity = JSON.parse(res.quant)),
        (this.lotno = res.lot),
        (this.itemcode = res.itemcode);
      (this.manufacturing = res.manufacturing),
        (this.expiry = res.expiry),
        (this.price = res.price);

      this.type = res.type;
      console.log(res);
      console.log(res, 'typeeeeeeeeeeee');
    });

    console.log(this.fermenter, 'pppppppppppppp');
  }

  ngOnInit(): void {
    this.print1();

    var date = new Date(this.manufacturing).getDate();
    if (date != 1) {
      if (new Date(this.expiry).getMonth() == 0) {
        this.year = new Date(this.expiry).getFullYear() - 1;
        this.expiry = this.monthArray[11] + '-' + this.year;
      } else {
        this.year = new Date(this.expiry).getFullYear();
        this.expiry =
          this.monthArray[new Date(this.expiry).getMonth() - 1] +
          '-' +
          this.year;
      }
    } else {
      this.expiry = this.datepipe.transform(this.expiry, 'MMM-yyyy');
    }
    console.log('dateeeeeeeeee', this.year);
  }

  print1() {
    this.Quantity = parseInt(this.Quantity);
    console.log(this.Quantity);
    if (this.type == 'planned')
      for (var n = 1; n <= this.Quantity; n++) {
        var j = n;
        this.qrcodearray.push({
          url:
            environment.qrcodeUrl +
            'smallboxNumber=' +
            this.production +
            '@' +
            this.fermenter.slice(0, 5) +
            '@' +
            this.Quantity +
            '@' +
            this.itemcode +
            '@' +
            this.lotno +
            '@' +
            j,
          local:
            this.production +
            '@' +
            this.fermenter.slice(0, 5) +
            '@' +
            this.Quantity +
            '@' +
            this.itemcode +
            '@' +
            this.lotno +
            '@' +
            j,
        });
      }
    else if (this.type == 'Unplanned') {
      for (var n = 1; n <= this.Quantity; n++) {
        console.log();
        var j = n;
        this.qrcodearray.push({
          url:
            environment.qrcodeUrl +
            'UnplannedInnerBox=' +
            this.production +
            '@' +
            this.Quantity +
            '@' +
            this.itemcode +
            '@' +
            this.lotno +
            '@' +
            j,

          local:
            this.production +
            '@' +
            this.Quantity +
            '@' +
            this.itemcode +
            '@' +
            this.lotno +
            '@' +
            j,
        });
      }
    }
  }

  Back() {
    window.close();
  }
}
