<div *ngIf="screendialog">
  <h3 mat-dialog-title>
    <span style="color: #007bff">{{ name }} </span>
  </h3>
  <mat-dialog-content>
    <li *ngFor="let data of screen">{{ data }}</li>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close tabindex="-1" color="warn">
      Cancel
    </button>
  </mat-dialog-actions>
</div>

<div *ngIf="printDeleteDialog">
  <mat-dialog-content>
    <div class="col-md-12 text-center" style="font-size: 40px">
      <label *ngIf="iconflag == 'print'"
        ><i class="fa fa-exclamation-circle" aria-hidden="true"></i
      ></label>
      <label *ngIf="iconflag == 'delete'"
        ><i class="fa fa-trash" aria-hidden="true"></i
      ></label>
    </div>

    <br />
    <label [innerHtml]="name"></label>
    <br />
    <mat-form-field
      class="example-full-width"
      appearance="outline"
      *ngIf="iconflag == 'print'"
    >
      <mat-label>Password</mat-label>
      <input
        matInput
        placeholder="Password"
        [(ngModel)]="password"
        type="password"
        [type]="hide ? 'password' : 'text'"
      />
      <mat-icon matSuffix (click)="hide = !hide">{{
        hide ? "visibility_off" : "visibility"
      }}</mat-icon>
      <mat-error>*Password is required</mat-error>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button
      mat-raised-button
      tabindex="-1"
      color="primary"
      (click)="generate()"
    >
      {{ submitbtn }}
    </button>

    <button
      *ngIf="cancelbtn"
      mat-raised-button
      mat-dialog-close
      tabindex="-1"
      color="warn"
      (click)="cancel()"
    >
      {{ cancelbtn }}
    </button>
  </mat-dialog-actions>
</div>

<div *ngIf="moredetails">
  <h3 mat-dialog-title class="text-center">
    <span style="color: #007bff">{{ name }} </span>
  </h3>
  <mat-dialog-content>
    <table>
      <tr>
        <th>Product Name &nbsp;</th>
        <td>:</td>
        <td>&nbsp; {{ details.product }}</td>
      </tr>
      <tr>
        <th>Manufacturing date &nbsp;</th>
        <td>:</td>
        <td>&nbsp;{{ details.manufacturing | date : "MMM-yyyy" }}</td>
      </tr>
      <tr>
        <th>Expiry Date &nbsp;</th>
        <td>:</td>
        <td>&nbsp;{{ details.expiry }}</td>
      </tr>
      <tr>
        <th>PackSlip Date &nbsp;</th>
        <td>:</td>
        <td>&nbsp;{{ details.detail.packslipdate | date : "dd/MM/yyyy" }}</td>
      </tr>
      <tr>
        <th>Dispatch Date &nbsp;</th>
        <td>:</td>
        <td>
          &nbsp;{{
            details.detail.dispatcheddate | date : "dd/MM/yyyy, h:mm a"
          }}
        </td>
      </tr>

      <tr>
        <th>Receiving Date &nbsp;</th>
        <td>:</td>
        <td>
          &nbsp;{{ details.detail.receiveddate | date : "dd/MM/yyyy, h:mm a" }}
        </td>
      </tr>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button mat-dialog-close tabindex="-1" color="warn">
      Close
    </button>
  </mat-dialog-actions>
</div>
