// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  ////////for Live//////////////////

  baseurluat: 'http://13.71.84.124:8085',
  PlannedInner: 'http://qrcode.mykumpany.com/scan-details?smallboxNumber=',
  PlannedOuter: 'http://qrcode.mykumpany.com/scan-details?bigboxNumber=',
  unplannedInner: 'http://qrcode.mykumpany.com/scan-details?UnplannedInnerBox=',
  unplannedOuter: 'http://qrcode.mykumpany.com/scan-details?UnplannedOuterBox=',
  qrcodeUrl: 'http://qrcode.mykumpany.com/scan-details?',

  ///////////for Uat///////////////////

  // baseurluat: 'http://52.172.38.1:8083',
  // PlannedInner: 'http://iplqr.mykumpany.com/scan-details?smallboxNumber=',
  // PlannedOuter: 'http://iplqr.mykumpany.com/scan-details?bigboxNumber=',
  // unplannedInner: 'http://iplqr.mykumpany.com/scan-details?UnplannedInnerBox=',
  // unplannedOuter: 'http://iplqr.mykumpany.com/scan-details?UnplannedOuterBox=',
  // qrcodeUrl: 'http://iplqr.mykumpany.com/scan-details?',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
