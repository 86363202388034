import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ApiService } from '../Api/api.service';

@Component({
  selector: 'app-scan-details',
  templateUrl: './scan-details.component.html',
  styleUrls: ['./scan-details.component.scss'],
})
export class ScanDetailsComponent implements OnInit {
  type: any;
  url: any;
  trackresponse: any;
  detailsArray: any = [];
  boxName: any;
  boxtype: any;
  url1: any;

  year: any;

  monthArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  constructor(
    private activaterouter: ActivatedRoute,
    private apiservice: ApiService,
    private datepipe: DatePipe
  ) {
    this.url1 = window.location.href;

    this.activaterouter.queryParams.subscribe((res: any) => {
      console.log(Object.keys(res)[0],'route')
      if (Object.keys(res)[0] == 'smallboxNumber') {
        console.log(res);
        this.boxtype = 'Planned Inner Box';
        this.boxName = Object.values(res)[0];
        this.type = 'innerBox';
        this.url = environment.PlannedInner + Object.values(res)[0];
      } else if (Object.keys(res)[0] == 'bigboxNumber') {
        this.boxtype = 'Planned Outer Box';
        this.boxName = Object.values(res)[0];
        this.type = 'outerBox';
        this.url = environment.PlannedOuter + Object.values(res)[0];
      } else if (Object.keys(res)[0] == 'UnplannedInnerBox') {
        this.boxtype = 'Unplanned Inner Box';
        this.boxName = Object.values(res)[0];
        this.type = 'UnplannedInnerBox';
        this.url = environment.unplannedInner + Object.values(res)[0];
      } else if (Object.keys(res)[0] == 'UnplannedInnerBox') {
        this.boxtype = 'Unplanned Outer Box';
        this.boxName = Object.values(res)[0];
        this.type = 'UnplannedInnerBox';
        this.url =
          'http://iplqr.mykumpany.com/scan-details?UnplannedInnerBox=' +
          Object.values(res)[0];
      }
    });
  }

  ngOnInit(): void {
    // this.tracklocationdetails();
    this.scanDetails();
  }

  // tracklocationdetails() {
  //   this.apiservice.tracklocation(this.url, this.type).subscribe((res: any) => {
  //     if (res.responseCode == 200) {
  //       this.trackresponse = res.responseMessage.data[0];
  //       this.scanDetails(this.trackresponse);
  //     }
  //   });
  // }

  // scanDetails(value: any) {
  //   this.apiservice.scandetails(value).subscribe((res: any) => {
  //     console.log(res);
  //     if (res.responseCode == 1) {
  //       this.detailsArray = res.responseMessage;
  //     }
  //   });
  // }

  scanDetails() {
    this.url1 = this.url1.replaceAll('%25', '/');
    this.apiservice.TenParamter(this.url1).subscribe(
      (res: any) => {
        if (res.responseCode == 200) {
          this.detailsArray = res.responseMessage;

          var date = new Date(
            this.detailsArray[0].Date_of_Manufacture
          ).getDate();
          if (date != 1) {
            if (new Date(this.detailsArray[0].Expiry_date).getMonth() == 0) {
              this.year =
                new Date(this.detailsArray[0].Expiry_date).getFullYear() - 1;
              this.detailsArray[0].Expiry_date =
                this.monthArray[11] + '-' + this.year;
            } else {
              this.year = new Date(
                this.detailsArray[0].Expiry_date
              ).getFullYear();
              this.detailsArray[0].Expiry_date =
                this.monthArray[
                  new Date(this.detailsArray[0].Expiry_date).getMonth() - 1
                ] +
                '-' +
                this.year;
            }
          } else {
            this.detailsArray[0].Expiry_date = this.datepipe.transform(
              this.detailsArray[0].Expiry_date,
              'MMM-yyyy'
            );
          }
        } else if (res.responseCode == 0) {
          this.apiservice.snackbar(res.responseMessage, 'red-snackbar');
        }
      },
      (Err: any) => {
        this.apiservice.snackbar('API Failed', 'red-snackbar');
      }
    );
  }
}
