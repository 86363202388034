<!-- <section class="vh-100" style="background-color: #508bfc;">
    <div class="container py-5 h-100">
      <div class="row d-flex justify-content-center align-items-center h-100">
        <div class="col-12 col-md-8 col-lg-7 col-xl-7">
          <div class="card shadow-2-strong" style="border-radius: 1rem;">
            <img src="../../assets/img/IPL_logo1.png" width="110" height="110">
  
            <label class="heading m-2">IPL QR-Code System</label>
            <div class="card-body p-5 text-center">

 
              <form [formGroup]="loginform" (ngSubmit)="submit()">  
  
                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>UserName</mat-label>
                    <input matInput placeholder="Enter UserName Here..." formControlName="username" type="text">
                    <mat-error  *ngIf="loginform.controls.username.hasError('required') && (loginform.controls.username.dirty || loginform.controls.username.touched)">UserName <strong>required</strong></mat-error>
                  </mat-form-field>

                    <mat-form-field class="example-full-width" appearance="outline">
                        <mat-label>Password</mat-label>
                  <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password" >
                  <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                  <mat-error *ngIf="loginform.controls.password.hasError('required') && (loginform.controls.password.dirty || loginform.controls.password.touched)">password <strong>required</strong></mat-error>
                  </mat-form-field>
   
  

  
  
              <button class="btn btn-primary btn-lg btn-block" type="submit">Login</button>
              <label class="text-center m-2 hov">Forgot Password</label>

  
</form>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> -->




  <section class="main">
    <div class="container-fluid ">
      <div class="row d-flex justify-content-center align-items-center h-100" style="margin-top: 10%;">
        <div class="col-md-9 col-lg-6 col-xl-5">
          <img src="https://mdbootstrap.com/img/Photos/new-templates/bootstrap-login-form/draw2.png" class="img-fluid"
            alt="Sample image">
        </div>
        <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
          <form [formGroup]="loginform" (ngSubmit)="submit()">
            <div class="row text-center" style="align-items: baseline;" >
              <div class="col-md-12">

           
              <!-- <img src="../../assets/img/IPL_logo1.png" width="110" height="110"> -->
              <h1 class="text-center heading">IPL QR Code System</h1>
            </div>
          </div>
  
            <mat-form-field class="example-full-width" appearance="outline">
                <mat-label>UserName</mat-label>
                <input matInput placeholder="Enter UserName Here..." formControlName="username" type="text" autocomplete="OFF">
                <mat-error  *ngIf="loginform.controls.username.hasError('required') && (loginform.controls.username.dirty || loginform.controls.username.touched)">UserName <strong>required</strong></mat-error>
              </mat-form-field>

                <mat-form-field class="example-full-width" appearance="outline">
                    <mat-label>Password</mat-label>
              <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password" >
              <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
              <mat-error *ngIf="loginform.controls.password.hasError('required') && (loginform.controls.password.dirty || loginform.controls.password.touched)">password <strong>required</strong></mat-error>
              </mat-form-field>

              <button class="btn btn-primary btn-lg btn-block" type="submit">Login</button>
              <label class="text-center m-2 hov">Forgot Password</label>

            <!-- <div class="text-center text-lg-start mt-4 pt-2">
              <button type="button" class="btn btn-primary btn-lg"
                style="padding-left: 2.5rem; padding-right: 2.5rem;">Login</button>
                <a href="#!" class="text-body">Forgot password?</a>
            </div> -->
  
          </form>
        </div>
      </div>
    </div>
  </section>